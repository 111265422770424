import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Button from "../elements/Button";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Turning ideas into reality",
    // paragraph: "Made With Love ❤️",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
          <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Your Private AI Companion
                </div>
                <h3 className="mt-0 mb-12">TAVERN</h3>
                {/* <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginBottom: 20 }}
                  wideMobile
                  href="https://twitter.com/messages/939936907861118976"
                >
                  Visit Allura
                </Button> */}
                <p className="m-0">
                  Tavern is your AI Companion with the ability to carry deep and powerful conversations to your heart's desire, with the ability to run it fully on your local machine for maximum privacy.
                </p>
                <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginTop: 20 }}
                  wideMobile
                  href="https://tajarib.ai"
                >
                  Visit Tavern
                </Button>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/tavern.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                  style={{ borderRadius: 50 }}
                />
              </div>
            </div>
          <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Purpose built Gen AI Assistant
                </div>
                <h3 className="mt-0 mb-12">HIVE</h3>
                <p className="m-0">
                  Hive is a simple and easy Gen AI assistant customized for any use case,  Runs anywhere from an old computer in your home, or deploy it in any cloud platform like Azure, Google Cloud or AWS.
                </p>
                <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginTop: 20 }}
                  wideMobile
                  href="https://tajarib.ai"
                >
                  Visit Hive
                </Button>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/hive.png")}
                  alt="Hive"
                  width={528}
                  height={396}
                  style={{ borderRadius: 50 }}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Run your business with a single link
                </div>
                <h3 className="mt-0 mb-12">ALLURA</h3>
                {/* <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginBottom: 20 }}
                  wideMobile
                  href="https://twitter.com/messages/939936907861118976"
                >
                  Visit Allura
                </Button> */}
                <p className="m-0">
                  Allura is an automated services booking platform to support
                  small to medium sized service providers like Salons, Spas,
                  Photographers and much more, our goal is to make any starting
                  businesses in Saudi have a streamlined communication channel
                  between them and their customers.
                </p>
                <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginTop: 20 }}
                  wideMobile
                  href="https://allura.app"
                >
                  Visit Allura
                </Button>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-01.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                  style={{ borderRadius: 50 }}
                />
              </div>
            </div>

            {/* <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  URL Link Shortener
                </div>
                <h3 className="mt-0 mb-12">BYTELINK</h3>
                <p className="m-0">
                  Bytelink is a simple and easy to use URL link shortener to
                  help solve the challenge of having long and painful links and
                  turning them into a short and beautiful links that have
                  unlimited expiration date for each short link created
                </p>
                <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginTop: 20 }}
                  wideMobile
                  href="https://bytelink.app"
                >
                  Visit Bytelink
                </Button>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-03.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  style={{ borderRadius: 50 }}
                />
              </div>
            </div> */}
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Interactive STORY EXPERIENCES
                </div>
                <h3 className="mt-0 mb-12">SAWALEIF</h3>
                <p className="m-0">
                  Sawaleif is an engaging storytelling platform aimed for
                  audiences who love the thrill of developing plots in the form
                  of a chat app conversation
                </p>
                <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginTop: 20 }}
                  wideMobile
                  href="https://apps.apple.com/us/app/%D8%B3%D9%88%D8%A7%D9%84%D9%8A%D9%81-%D9%82%D8%B5%D8%B5-%D8%B4%D8%A7%D8%AA/id1387307850"
                >
                  Visit Sawaleif
                </Button>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-04.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  style={{ borderRadius: 50 }}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Unlimited Storage
                </div>
                <h3 className="mt-0 mb-12">Private Cloud Storage</h3>
                <p className="m-0">
                  A step-by-step guide on how to install and deploy your own
                  cloud storage with complete control over its security and
                  collaboration
                </p>
                <Button
                  tag="a"
                  style={{ backgroundColor: "white", marginTop: 20 }}
                  wideMobile
                  href="https://medium.com/@eisa.io/dedicated-cloud-storage-with-nextcloud-66c61da3bf84"
                >
                  Visit Article
                </Button>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-05.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                  style={{ borderRadius: 50 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
